import AdministrationSegments from "@/router/routes/administration/AdministrationSegments"
import AccountManagement from "@/router/routes/administration/AccountManagement"
import Integrations from "@/router/routes/administration/Integrations"
import Plans from "@/router/routes/administration/Plans"
import PlanManagement from "@/router/routes/administration/PlanManagement"
import Proposals from "@/router/routes/administration/Proposals"
import Users from "@/router/routes/administration/Users"
import WebPhone from "@/router/routes/administration/WebPhones"
import ExpressionTemplates from "@/router/routes/administration/ExpressionTemplates"
import environment from "@/shared/environment"
import Snippet from "@/router/routes/administration/TranslateVariables/Snippet"
import TalkChannels from "@/router/routes/administration/TalkChannels"
import Restorer from "@/router/routes/administration/Restorer"

export default [
    {
        name: "administration.index",
        path: "administration",
        component: () => import("@/pages/administration/AdministrationIndex"),
        meta: {
            title: "Administração",
            description: "Configurações gerais para administradores do sistema.",
            accessPermission: "Administrador",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Administração",
                    show: ".*",
                },
            ],
        },
    },
    ...Snippet,
    ...AccountManagement,
    ...AdministrationSegments,
    ...Integrations,
    ...Plans,
    ...PlanManagement,
    ...Proposals,
    ...Users,
    ...WebPhone,
    ...ExpressionTemplates,
    ...TalkChannels,
    ...Restorer,
]
