import { http } from "@/plugins/http"
import environment from "@/shared/environment"

export const fetch = (store) => {
    return http.get("/customConfigs").then(
        ({ data }) => {
            store.commit("SET_CUSTOM_CONFIGS", data.data)

            return data
        }
    )
}

export const saveConfig = (store, payload) => {
    let urlYii = environment.APP_YII
    // O get abaixo atualiza a custom config na sessão Yii do usuário logado (sem isso, a custom config só irá atualizar ao deslogar e logar no sistema)
    http.get(`${urlYii}/webservice/getCustomConfig?param=${payload.param}&value=${payload.value}`)

    return http.put("/customConfigs",
        payload).then(
        ({ data }) => {
            store.commit("SET_ID_CUSTOM_CONFIGS", payload)

            return data
        }
    )
}
