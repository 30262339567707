export const setCustomFormData = ({ commit }, customFormData) => {
    commit("SET_CUSTOM_FORM_DATA", customFormData)
}

export const setCustomFormFields = ({ commit }, customFormFields) => {
    commit("SET_CUSTOM_FORM_FIELDS", customFormFields)
}

export const setHasFields = ({ commit }, hasFields) => {
    commit("SET_HAS_FIELDS", hasFields)
}

export const setDraggingField = ({ commit }, field) => {
    commit("SET_DRAGGING_FIELD", field)
}

export const setUsedInTriggers = ({ commit }, field) => {
    commit("SET_USED_IN_TRIGGERS", field)
}

export const allAvailableFields = ({ availableFields }) => availableFields

