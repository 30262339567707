class EvironmentConfig {
    APP_CLIENT_ID = import.meta.env.VITE_CLIENT_ID
    APP_API = import.meta.env.VITE_API
    APP_YII = import.meta.env.VITE_YII
    APP_SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT
    APP_SENTRY_RELEASE = import.meta.env.VITE_SENTRY_RELEASE
    APP_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
    APP_MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN
    APP_HOTJAR_SITE_ID = import.meta.env.VITE_HOTJAR_SITE_ID
    APP_MOVIDESK_URL = import.meta.env.VITE_MOVIDESK_URL
    APP_MOVIDESK_CHAT = import.meta.env.VITE_MOVIDESK_CHAT
    APP_CRMA_CHAT_ID = import.meta.env.VITE_CRMA_CHAT_ID
    APP_ALPHA = import.meta.env.VITE_ALPHA === "true"
    APP_MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN
    APP_GOOGLE_MAPS_TOKEN = import.meta.env.VITE_GOOGLE_MAPS_TOKEN
    APP_USERPILOT_TOKEN = import.meta.env.VITE_USERPILOT_TOKEN
    APP_ELEVIO_ID = import.meta.env.VITE_ELEVIO_ID
    APP_PUSHER_CHANNELS_HOST = import.meta.env.VITE_PUSHER_CHANNELS_HOST
    APP_PUSHER_CHANNELS_KEY = import.meta.env.VITE_PUSHER_CHANNELS_KEY
    APP_FB_APP_ID = import.meta.env.VITE_FB_APP_ID
    APP_FB_APP_VERSION = import.meta.env.VITE_FB_APP_VERSION
    APP_FB_WHATSAPP_META_APP_ID = import.meta.env.VITE_FB_WHATSAPP_META_APP_ID
    APP_FB_WHATSAPP_META_CONFIG_ID = import.meta.env.VITE_FB_WHATSAPP_META_CONFIG_ID
    APP_FB_WHATSAPP_META_APP_VERSION = import.meta.env.VITE_FB_WHATSAPP_META_APP_VERSION
    APP_BEEFREE_PAGE_CLIENT_ID = import.meta.env.VITE_BEEFREE_PAGE_CLIENT_ID
    APP_BEEFREE_PAGE_CLIENT_SECRET = import.meta.env.VITE_BEEFREE_PAGE_CLIENT_SECRET
    APP_BEEFREE_EMAIL_CLIENT_ID = import.meta.env.VITE_BEEFREE_EMAIL_CLIENT_ID
    APP_BEEFREE_EMAIL_CLIENT_SECRET = import.meta.env.VITE_BEEFREE_EMAIL_CLIENT_SECRET
    BASE_URL = import.meta.env.VITE_BASE_URL
    VERSION = import.meta.env.VERSION
    APP_TINYMCE_HASH = process.env.APP_TINYMCE_HASH
}

export default new EvironmentConfig()
