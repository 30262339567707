export default {
    root: {
        color: "{primary.gray.default}",
        shadow: "0 1px 2px 0 #0000000F, 0 1px 3px 0 #0000001A", // @shadow-sm
        borderRadius: "8px",
    },
    body: {
        padding: "16px 16px 12px 16px",
    },
}
