export default {
    tab: {
        color: "{primary.gray.lighten}",
        activeColor: "{primary.green.default}",
        hoverColor: "{primary.gray.darken}",
        padding: "8px 16px",
    },
    tabpanel: {
        padding: "8px 0 0 0",
    },
}
