export default {
    root: {
        background: "#ffffff", // @background-primary
    },
    submenuLabel: {
        color: "#9CA3AF", // @primary-gray-lighten
    },
    item: {
        color: "#676a6c", // @secondary-gray
        focusColor: "#EF4444", // @primary-red
        focusBackground: "#f3f3f4", // @background-secondary
    },
}
