export const USER_SCOPE_GENERAL =    0
export const USER_SCOPE_RESTRICTED = 1
export const USER_SCOPE_TEAMS =      2

export const userScope = {
    [USER_SCOPE_GENERAL]:    "Acesso geral",
    [USER_SCOPE_RESTRICTED]: "Acesso restrito",
    [USER_SCOPE_TEAMS]:      "Acesso restrito | Líder de equipe | Grupos de equipes",
}

export const userScopeOptions = Object.keys(userScope).map(key => {
    return { id: Number(key), name: userScope[key] }
})
