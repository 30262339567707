export default {
    red: {
        100: "#FEF2F2",
        200: "#FEE2E2",
        400: "#F87171",
        500: "#EF4444",
        600: "#DC2626",
    },
    yellow: {
        100: "#FFFBEB",
        200: "#FEF3C7",
        400: "#FBBF24",
        500: "#F59E0B",
        600: "#D97706",
    },
    green: {
        100: "#E8F7F4",
        200: "#D1F0EA",
        400: "#48C2A9",
        500: "#1AB394",
        600: "#14866F",
    },
    blue: {
        100: "#F0F9FF",
        200: "#F0F9FF",
        400: "#38BDF8",
        500: "#0EA5E9",
        600: "#0284C7",
    },
    gray: {
        0: "#F9FAFB",
        100: "#F9FAFB",
        200: "#F3F4F6",
        300: "#E5E7EB",
        400: "#9CA3AF",
        500: "#6B7280",
        600: "#4B5563",
        700: "#374151",
    },
}
