import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import {
    IMPORT_ENTITY_COMPANY,
    IMPORT_ENTITY_DEAL,
    IMPORT_ENTITY_PERSON,
    IMPORT_ENTITY_USER,
} from "@/pages/import/consts/entities"

const meta = {
    title: "Importações",
    description: "Faça novas importações. Na aba de históricos, consulte importações realizadas ou em andamento.",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Importações",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "settings/imports",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                path: "",
                component: () => import("@/pages/import/ImportIndex"),
                meta,
                children: [
                    {
                        name: "imports.index",
                        path: "",
                        component: () => import("@/pages/import/ImportsList"),
                        meta,
                    },
                    {
                        path: "history",
                        component: () => import("@/layouts/EmptyRouterLayout"),
                        meta,
                        children: [
                            {
                                name: "imports.history",
                                path: "",
                                component: () => import("@/pages/import/history/ImportHistoryIndex"),
                                meta,
                            },
                            {
                                name: "imports.history.details",
                                path: ":id",
                                props: true,
                                component: () => import("@/pages/import/history/ImportDetails"),
                                meta,
                            },
                        ],
                    },
                ],
            },
            {
                name: "imports.new.deal",
                path: "deal/new",
                props: {
                    importEntity: IMPORT_ENTITY_DEAL,
                },
                component: () => import("@/pages/import/new/ImportNew"),
            },
            {
                name: "imports.new.company",
                path: "company/new",
                props: {
                    importEntity: IMPORT_ENTITY_COMPANY,
                },
                component: () => import("@/pages/import/new/ImportNew"),
            },
            {
                name: "imports.new.person",
                path: "person/new",
                props: {
                    importEntity: IMPORT_ENTITY_PERSON,
                },
                component: () => import("@/pages/import/new/ImportNew"),
            },
            {
                name: "imports.new.user",
                path: "user/new",
                props: {
                    importEntity: IMPORT_ENTITY_USER,
                },
                component: () => import("@/pages/import/new/ImportNew"),
            },
        ],
    },
]
