export const ACCOUNT_FLAG_ALLOWS_REGIONS_MICROREGIONS = "allows_regions_microregions"
export const ACCOUNT_FLAG_ALLOWS_CONTENT_CATEGORIES = "allows_content_categories"
export const ACCOUNT_FLAG_ALLOWS_PROPOSALS = "allows_proposals"
export const ACCOUNT_FLAG_ALLOWS_DEAL_CLOSE_REPLICATE = "allows_deal_close_replicate"
export const ACCOUNT_FLAG_ALLOWS_AUTOMATIONS = "allows_automations"
export const ACCOUNT_FLAG_ALLOWS_DOCUMENTS_SIGNATURE = "allows_documents_signature"
export const ACCOUNT_FLAG_ALLOWS_ACTITVIES_MAP = "allows_activities_map"
export const ACCOUNT_FLAG_ALLOWS_CONTENT = "allows_content_categories"
export const ACCOUNT_FLAG_ALLOWS_FORECAST = "allows_forecast"
export const ACCOUNT_FLAG_ALLOWS_CUSTOM_FORMS = "allows_custom_forms"
export const ACCOUNT_FLAG_ALLOWS_HOLDING = "allows_holding"
export const ACCOUNT_FLAG_ALLOWS_ITEMS = "allows_items"
export const ACCOUNT_FLAG_ALLOWS_AUTHENTICATIONS = "allows_authentications"
export const ACCOUNT_FLAG_ALLOWS_OLD_DOCUMENT_MODEL = "allows_old_document_model"
export const ACCOUNT_FLAG_ALLOW_BI_HORUSBI = "allows_bi_horusbi"

export const ACCOUNT_FLAGS_DEPENDENCY = {
    [ACCOUNT_FLAG_ALLOWS_PROPOSALS]: [ACCOUNT_FLAG_ALLOWS_ITEMS],
}
export default {
    ACCOUNT_FLAG_ALLOWS_REGIONS_MICROREGIONS,
    ACCOUNT_FLAG_ALLOWS_CONTENT_CATEGORIES,
    ACCOUNT_FLAG_ALLOWS_PROPOSALS,
    ACCOUNT_FLAG_ALLOWS_DEAL_CLOSE_REPLICATE,
    ACCOUNT_FLAG_ALLOWS_AUTOMATIONS,
    ACCOUNT_FLAG_ALLOWS_DOCUMENTS_SIGNATURE,
    ACCOUNT_FLAG_ALLOWS_ACTITVIES_MAP,
    ACCOUNT_FLAG_ALLOWS_CONTENT,
    ACCOUNT_FLAG_ALLOWS_FORECAST,
    ACCOUNT_FLAG_ALLOWS_CUSTOM_FORMS,
    ACCOUNT_FLAG_ALLOWS_HOLDING,
    ACCOUNT_FLAG_ALLOWS_ITEMS,
    ACCOUNT_FLAG_ALLOWS_AUTHENTICATIONS,
    ACCOUNT_FLAG_ALLOWS_OLD_DOCUMENT_MODEL,
    ACCOUNT_FLAGS_DEPENDENCY,
    ACCOUNT_FLAG_ALLOW_BI_HORUSBI,
}
