export const LISTEN_BULK_ACTION_FAILED = "bulkAction.failed"
export const LISTEN_BULK_ACTION_PROGRESS = "bulkAction.progress"
export const LISTEN_BULK_ACTION_SUCCESSFUL = "bulkAction.successful"
export const LISTEN_EXPORT_FAILED = "export.failed"
export const LISTEN_EXPORT_PROGRESS = "export.progress"
export const LISTEN_EXPORT_SUCCESSFUL = "export.successful"
export const LISTEN_IMPORT_FAILED = "import.failed"
export const LISTEN_IMPORT_SUCCESSFUL = "import.successful"
export const LISTEN_NOTIFICATION_CREATED = "notification.created"
export const LISTEN_TOKEN_REVOKED = "token.revoked"
export const LISTEN_ACTIVITY_ALERT = "activity.alert"
export const LISTEN_TRANSCRIBE_FAILED = "transcribe.failed"
export const LISTEN_TRANSCRIBE_SUCCESSFUL = "transcribe.successful"
export const LISTEN_ANALYZE_FAILED = "analyze.failed"
export const LISTEN_ANALYZE_SUCCESSFUL = "analyze.successful"
export const LISTEN_GOAL_RECURRENCE_FAILED = "goal.recurrence.failed"
export const LISTEN_GOAL_RECURRENCE_SUCCESSFUL = "goal.recurrence.successful"
export const LISTEN_WHATSAPP_TEMPLATE_IMPORT_SUCCESSFUL = "whatsapp.template.import.successful"
export const LISTEN_WHATSAPP_TEMPLATE_IMPORT_FAILED = "whatsapp.template.import.failed"

export { default as usePushNotification } from "./pushNotification"
