export default {
    primary: {
        red: {
            moreDesaturated: "{red.100}",
            desaturated: "{red.200}",
            lighten: "{red.400}",
            default: "{red.500}",
            darken: "{red.600}",
        },
        yellow: {
            moreDesaturated: "{yellow.100}",
            desaturated: "{yellow.200}",
            lighten: "{yellow.400}",
            default: "{yellow.500}",
            darken: "{yellow.600}",
        },
        green: {
            moreDesaturated: "{green.100}",
            desaturated: "{green.200}",
            lighten: "{green.400}",
            default: "{green.500}",
            darken: "{green.600}",
        },
        blue: {
            moreDesaturated: "{blue.100}",
            desaturated: "{blue.200}",
            lighten: "{blue.400}",
            default: "{blue.500}",
            darken: "{blue.600}",
        },
        gray: {
            moreDesaturated: "{gray.100}",
            desaturated: "{gray.200}",
            border: "{gray.300}",
            lighten: "{gray.400}",
            default: "{gray.500}",
            darken: "{gray.600}",
            moreDarken: "{gray.700}",
        },
    },
}
