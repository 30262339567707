import { definePreset } from "@primevue/themes"
import Aura from "@primevue/themes/aura"
import base from "./base"
import chip from "./components/chip"
import checkbox from "./components/checkbox"
import menu from "./components/menu"
import card from "./components/card"
import tabs from "./components/tabs"

export default definePreset(Aura, {
    ...base,
    components: {
        chip,
        checkbox,
        menu,
        card,
        tabs,
    },
})
