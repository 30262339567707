import environment from "@/shared/environment"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"
import { ACCOUNT_FLAG_ALLOWS_ACTITVIES_MAP } from "@/router/routes/settings/variables/AccountFlagsVariables"

export default [
    {
        path: "activities",
        component: () => import("@/layouts/EmptyRouterLayout"),
        children: [
            {
                name: "activities.index",
                path: "",
                component: () => import("@/pages/activities/ActivitiesIndex"),
                meta: {
                    title: "Listagem de atividades",
                    accessPermission: "C1",
                    breadcrumb: [
                        {
                            label: "Home",
                            href: environment.APP_YII,
                            show: ".*",
                            external: true,
                        },
                        {
                            label: "Atividades",
                            show: ".*",
                        },
                    ],
                },
                children: [
                    {
                        name: "activities.list",
                        path: "",
                        props: true,
                        component: () => import("@/pages/activities/ActivitiesList"),
                    },
                    {
                        name: "activities.calendar",
                        path: "calendar",
                        props: true,
                        component: () => import("@/pages/activities/ActivitiesCalendar"),
                    },
                ],
            },
            {
                name: "activities.map",
                path: "map",
                component: () => import("@/pages/activities/ActivitiesMapIndex"),
                beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_ACTITVIES_MAP),
                meta: {
                    customConfig: "ATIVIDADE_COORDENADAS_CONCLUIR",
                },
            },
        ],
    },
]
