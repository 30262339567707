// PipeRun logo
const pipeLoginLogo = "https://static.pipe.run/logos/piperun_color_black_1000x250.png"
const pipeLogoRed = "https://static.pipe.run/icons/favicon-red.svg"
const pipeLogoWhite = "https://static.pipe.run/icons/favicon-white.svg"
const pipeLogoWhiteRedBg = "https://static.pipe.run/icons/favicon-white-red.svg"
const pipeAtendimentoLogoBlack = "https://static.pipe.run/logos/logo-piperun-atendimento-black.png"
const pipeLogoMedium = "https://static.pipe.run/logos/piperun_color_black_386x180.png"

// Generic
const error404 = "https://static.pipe.run/images/errors/404.svg"

// Placeholder images
const companyPlaceholder = "https://static.pipe.run/placeholders/company.png"
const productPlaceholder = "https://static.pipe.run/placeholders/product.png"
const userPlaceholder = "https://static.pipe.run/placeholders/user.png"
const dropHerePlaceholder = "https://static.pipe.run/placeholders/drop-here.svg"

// Loading animations
const loadingRed = "https://static.pipe.run/loading/loading-red.svg"
const loadingRedFast = "https://static.pipe.run/loading/loading-red-fast.svg"
const loadingWhite = "https://static.pipe.run/loading/loading-white.svg"
const loadingWhiteFast = "https://static.pipe.run/loading/loading-white-fast.svg"

// File models
export const importDealXlsxFileModel = "https://static.pipe.run/doc/import/exemplo-importacao-oportunidades.xlsx"
export const importCompanyXlsxFileModel = "https://static.pipe.run/doc/import/exemplo-importacao-empresas.xlsx"
export const importPersonXlsxFileModel = "https://static.pipe.run/doc/import/exemplo-importacao-pessoas.xlsx"
export const importActivitiesXlsxFileModel = "https://static.pipe.run/doc/import/modelo-importacao-atividades.xls"
export const importLostReasonXlsxFileModel = "https://static.pipe.run/doc/import/modelo-importacao-motivos-de-perda.xls"
export const importProductsXlsxFileModel = "https://static.pipe.run/doc/import/modelo-importacao-produtos.xls"
export const importRegionsXlsxFileModel = "https://static.pipe.run/doc/import/modelo-importacao-regioes.xlsx"
export const importUsersXlsxFileModel = "https://static.pipe.run/doc/import/exemplo-importacao-usuarios.xlsx"

// Account CRMA
const configCrmaAuth = "https://static.pipe.run/images/config/crma/auth.webp"

// Proposal Model
const proposalModelDefault = "https://static.pipe.run/images/proposal-model/pdf-sample-proposal.png"
const proposalModel = "https://static.pipe.run/images/proposal-model/pdf-sample.png"

export default function install(app) {
    app.config.globalProperties.$static = {
        pipeLoginLogo,
        pipeLogoRed,
        pipeLogoWhite,
        pipeLogoWhiteRedBg,
        pipeAtendimentoLogoBlack,
        companyPlaceholder,
        productPlaceholder,
        userPlaceholder,
        dropHerePlaceholder,
        loadingRed,
        loadingRedFast,
        loadingWhite,
        loadingWhiteFast,
        pipeLogoMedium,
        error404,
        configCrmaAuth,
        importDealXlsxFileModel,
        importCompanyXlsxFileModel,
        importPersonXlsxFileModel,
        proposalModelDefault,
        proposalModel,
    }
}
