import AdministrationBreadcrumbs from "@/router/routes/administration/breadcrumbs/AdministrationBreadcrumbs"

const meta = {
    title: "Restaurador",
    description: "Restaure registros apagados.",
    breadcrumb: [
        ...AdministrationBreadcrumbs,
        {
            label: "Restaurador",
            show: ".*",
        },
    ],
}

export default [
    {
        path: "administration/restorer",
        name: "restorer.main",
        component: () => import("@/pages/administration/restorer/RestorerMain"),
        meta,
    },
]
