import Hotjar from "@hotjar/browser"
import environment from "@/shared/environment"

export function identify(userId, attributes) {
    Hotjar.identify(userId, attributes)
}

export default function install(app) {
    Hotjar.init(environment.APP_HOTJAR_SITE_ID, 6)

    app.config.globalProperties.$hotjar = {
        identify,
    }
}
