let isRefreshing = false
let requestQueue = []
const MAX_REFRESH_ATTEMPTS = 10

/* Processa a fila de requisições */
const processQueue = accessToken => {
    /* Resolve ou rejeita as requests da fila */
    requestQueue.forEach(request => {
        if (accessToken) {
            request.resolve(accessToken)
        } else {
            request.reject("Sem token.")
        }
    })
    /* Limpa a fila */
    requestQueue = []
}

export const createResponseInterceptors = (http, { dispatch }, router, attempt = 1) => {
    const interceptor = http.interceptors.response.use(
        response => response,
        async (error) => {
            const { response } = error

            /* Resposta vazia da API */
            if (!response) {
                return Promise.reject({
                    message: "Resposta vazia da API: " + error,
                    error,
                })
            }

            /* Trial expirado */
            if (response.status === 402) {
                return router.push({ name: "login.auth", query: { expired: "" } }).catch(err => err)
            }
            /* Se não for autorizado */
            if (response.status === 401) {
                /* Se estiver atualizando o token*/
                if (isRefreshing) {
                    /* Retorna uma promise para manter na mesma cadeia */
                    return new Promise((resolve, reject) => {
                        requestQueue.push({ resolve, reject })
                    }).then(accessToken => {
                        response.config.headers.Authorization = accessToken

                        return http.request(response.config)
                    }).catch(queueError => {
                        return Promise.reject(queueError)
                    })
                }

                /* Ejeta o interceptor para garantir que a requisição seja reiniciada uma vez só */
                http.interceptors.response.eject(interceptor)

                /* Está atualizando o refresh token */
                isRefreshing = true

                /* Executa action para validar o refresh e retorna a promise resultante*/
                await dispatch("auth/refreshToken")
                    .then(accessToken => {
                        if (accessToken) {
                            /* Troca o token inválido pelo novo */
                            response.config.headers.Authorization = accessToken

                            /* E processa a fila de requisições */
                            return processQueue(accessToken)
                        }
                        Promise.reject("No access token has been returned by the authentication.")
                    })
                    /* Se o refresh for inválido redireciona para o login */
                    .catch(refreshError => {
                        router.push({ name: "login.auth" })
                            .catch(routerError => routerError)
                        Promise.reject(refreshError)
                    })
                    /* Permite novos refreshes */
                    .finally(() => {
                        /* Reinicia a queue e reinstala o interceptor */
                        isRefreshing = false

                        if (attempt > MAX_REFRESH_ATTEMPTS) {
                            return Promise.reject("Não foi possível renovar token")
                        }
                        createResponseInterceptors(http, { dispatch }, router, ++attempt)
                    })

                /* Refaz a requisição */
                return http.request(response.config)
            }

            /* Mantém o retorno como promise */
            return Promise.reject(error)
        }
    )
}
