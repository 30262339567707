import primitive from "./primitive"
import semantic from "./semantic"

export default {
    primitive: {
        ...primitive,
    },
    semantic: {
        ...semantic,
        formField: {
            borderRadius: "4px",
            paddingX: "6px",
            paddingY: "8px",
            lg: {
                fontSize: "1.3rem",
            },
        },
        navigation: {
            item: {
                padding: "8px 4px",
                gap: "4px",
            },
            submenu: {
                iconSize: "13px",
            },
        },
        colorScheme: {
            formField: {
                borderColor: "{primary.gray.border}",
                color: "#676A6C", //@secondary-gray
                placeholder: "#ADADAD",
                disabledBackground: "{primary.gray.border}",
                iconColor: "{primary.gray.default}",
            },
            text: {
                color: "#676A6C", //@secondary-gray
            },
            navigation: {
                item: {
                    color: "{text.color}", // @secondary-gray
                    focusColor: "#EF4444", // @primary-red
                    focusBackground: "#f3f3f4", // @background-secondary
                },
                submenuLabel: {
                    color: "#9CA3AF", // @primary-gray-lighten
                },
            },
        },
    },
}
